import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Paper,
  Box,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FinvestLogo from "./assets/finvest-logo.png";

const GetAgreements = () => {
  const [loading, setLoading] = useState(true);
  const [userAgreements, setUserAgreements] = useState(null);
  const [agreedDocs, setAgreedDocs] = useState([]);
  const [approvalKeys, setApprovalKeys] = useState([]);
  const [ipAddress, setIpAddress] = useState("");
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const token = searchParams.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the user's IP address from a third-party service
    const fetchIpAddress = async () => {
      try {
        const ipResponse = await fetch("https://api.ipify.org?format=json");
        const ipData = await ipResponse.json();
        setIpAddress(ipData.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
        setIpAddress(""); // Set empty string if fetch fails
      }
    };

    const fetchAgreements = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/additional-user-agreements-email?external_user_id=${id}&token=${token}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );

        const data = await response.json();
        console.log(data);
        setUserAgreements(data.status === "success" ? "success" : data);

        // Extract all agreement document keys and approval keys
        if (data.items) {
          const approvalKeysList = [];
          data.items.forEach((item) => {
            if (item.type === "approvals-multi-select" && item.elements) {
              item.elements.forEach((approval, index) => {
                approvalKeysList.push(approval.key || `approval-${index}`);
              });
            }
          });
          setApprovalKeys(approvalKeysList);
          console.log("Approval Keys set:", approvalKeysList); // Check if approvalKeys is correctly extracted
        }
      } catch (error) {
        console.error("Error fetching agreements:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchIpAddress();
    fetchAgreements();
  }, [id, token]);

  useEffect(() => {
    // Handle navigation in useEffect, not during render
    if (userAgreements === "success") {
      navigate("/user/additional-agreements/success");
    }
  }, [userAgreements, navigate]);

  const handleCheckboxChange = (key, isChecked) => {
    if (isChecked) {
      setAgreedDocs([...agreedDocs, key]);
    } else {
      setAgreedDocs(agreedDocs.filter((docKey) => docKey !== key));
    }
  };

  const handleAgree = async () => {
    if (agreedDocs.length !== approvalKeys.length) {
      alert("Please approve all agreements before continuing.");
      return;
    }

    try {
      setLoading(true); // Show loading spinner

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/additional-user-agreements-email?external_user_id=${id}&token=${token}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            agreed_document_ids: agreedDocs,
            ip_address: ipAddress || "127.0.0.1",
          }),
        }
      );

      const result = await response.json();
      console.log("Server Response:", result); // Debug log

      if (response.status === 200) {
        navigate("/user/additional-agreements/success");
      } else {
        setLoading(false);
        alert("Failed to accept agreements.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error submitting agreements:", error);
      alert("An error occurred while submitting agreements.");
    }
  };

  if (loading) {
    return <CircularProgress sx={{ display: "block", margin: "auto" }} />;
  }

  // Skip rendering if we already know we're successful
  // but don't navigate in the render function
  if (userAgreements === "success") {
    return <CircularProgress sx={{ display: "block", margin: "auto" }} />;
  }

  // Fix for approvals-multi-select rendering
  const renderApprovalItem = (approval, i) => {
    if (!approval.parts) {
      return null;
    }

    // Ensure the key is valid
    const approvalKey = approval.key || `approval-${i}`;

    const label = (
      <Typography component="span" sx={{ fontFamily: 'inherit' }}>
        {approval.parts.map((part, index) => (
          <React.Fragment key={index}>
            {part.url ? (
              <a
                href={part.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#3856DD", textDecoration: "underline" }}
              >
                {part.text}
              </a>
            ) : (
              <span>{part.text}</span>
            )}
            &nbsp;
          </React.Fragment>
        ))}
      </Typography>
    );

    return (
      <FormControlLabel
        key={i}
        control={
          <Checkbox
            checked={agreedDocs.includes(approvalKey)}
            onChange={(e) =>
              handleCheckboxChange(approvalKey, e.target.checked)
            }
            sx={{
              paddingTop: '2px',
              paddingLeft: '0px',
              '&.Mui-checked': {
                color: '#3856DD'
              }
            }}
          />
        }
        label={<Typography sx={{ fontFamily: 'inherit',fontWeight: 'bold' }}>{label}</Typography>}
        sx={{ alignItems: 'flex-start', margin: 0}}
      />
    );
  };

  return (
    <Container maxWidth="sm" sx={{ fontFamily: '"Inter", "Roboto", "Helvetica Neue", Arial, sans-serif' }}>
      <Paper sx={{ p: 4 }}>
        <Box display="flex" justifyContent="center" my={3}>
          <img
            src={FinvestLogo}
            alt="Finvest Logo"
            style={{ width: "150px" }}
          />
        </Box>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ textAlign: "center", fontWeight: "bold", mb: 4 }}
        >
          {userAgreements?.title}
        </Typography>
        {userAgreements?.items?.map((item, index) => (
          <Box key={index} mb={2}>
            {item.type === "text" && (
              <Typography sx={{ fontFamily: 'inherit' }}>{item.description}</Typography>
            )}
            {item.type === "agreement-documents" &&
              item.elements?.map((doc) => (
                <Typography key={doc.key} sx={{ fontFamily: 'inherit' }}>
                  <a href={doc.url} target="_blank" rel="noopener noreferrer" style={{ color: "#3856DD", textDecoration: "underline" }}>
                    {doc.displayName}
                  </a>
                </Typography>
              ))}
            {item.type === "bullet-list" && (
              <ul>
                {item.elements?.map((point, i) => (
                  <li key={i} sx={{ alignItems: "flex-start" }}>
                    <Typography sx={{ fontFamily: 'inherit' }}>{point}</Typography>
                  </li>
                ))}
              </ul>
            )}
            {item.type === "approvals-multi-select" && (
              <>
                {item.elements?.map((approval, i) => (
                  <Box key={i} mb={2}>
                    {renderApprovalItem(approval, i)}
                  </Box>
                ))}
              </>
            )}
          </Box>
        ))}
        <Button
          variant="contained"
          color="primary"
          mt={4}
          fullWidth
          onClick={handleAgree}
          disabled={agreedDocs.length < approvalKeys.length}
          sx={{
            backgroundColor: "#3856DD",
            borderRadius: "10px",
            textTransform: "none",
            fontWeight: "bold",
            height: "48px",
            fontSize: "16px",
            "&:hover": {
              backgroundColor: "#2e46c0",
            },
          }}
        >
          Agree
        </Button>
      </Paper>
    </Container>
  );
};

export default GetAgreements;
