import React from "react";
import ResetPassword from "./ResetPassword";
import PasswordResetConfirmation from "./PasswordResetConfirmation";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import EmailPreferenceResetConfirmation from "./EmailPreferencesResetConfirmation";
import EmailPreferences from "./EmailPreferences";
import GetAgreements from "./UserAgreement";
import SubmitAgreements from "./UserAgreementsConfirmation";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/accounts/passwordreset" element={<ResetPassword />} />
        <Route
          path="/accounts/passwordreset/success"
          element={<PasswordResetConfirmation />}
        />
        <Route
          path="/accounts/emailpreferences"
          element={<EmailPreferences />}
        />
        <Route
          path="/accounts/emailpreferences/success"
          element={<EmailPreferenceResetConfirmation />}
        />
        <Route path="/user/additional-agreements" element={<GetAgreements />} />
        <Route
          path="/user/additional-agreements/success"
          element={<SubmitAgreements />}
        />
      </Routes>
    </Router>
  );
}

export default App;
