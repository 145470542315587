import React from "react";
import { Container, Box, Paper, Typography } from "@mui/material";
import FinvestLogo from "./assets/finvest-logo.png";

const SubmitAgreements = () => {
  return (
    <Container sx={{ textAlign: "center", mt: 3, fontFamily: '"Inter", "Roboto", "Helvetica Neue", Arial, sans-serif' }}>
      <Box display="flex" justifyContent="center" my={2}>
        <img src={FinvestLogo} alt="Finvest Logo" style={{ width: "150px" }} />
      </Box>
      <Paper sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="h5" sx={{ fontFamily: 'inherit', textAlign: 'center', fontWeight: 'bold', mb: 2 }}>You're all set</Typography>
        <Typography sx={{ fontFamily: 'inherit' }}>
          Your account migration is confirmed. You'll be notified once
          everything is complete.
        </Typography>
      </Paper>
    </Container>
  );
};

export default SubmitAgreements;
